<template>
  <div class="relative px-6 py-6">
    <slot></slot>
    <!-- Slot for parent component -->
    <div
      v-if="showMessage && headline"
      ref="messageBox"
      :class="['absolute', position, msgClasses, 'max-w-[90vw]', 'min-w-[150px]']"
    >
      <!-- Image and Headline Section -->
      <div v-if="imageUrl" class="relative w-full h-auto overflow-hidden rounded-t-lg">
        <img class="w-full h-full object-cover" :src="imageUrl" alt="headline image" />
        <!--- Headline Text should be included in the image displayed -->
      </div>

      <!-- Close Button -->
      <button
        class="absolute top-4 right-4 text-white text-opacity-80 hover:text-opacity-100 rounded-full p-2"
        @click="closeMsgBox"
      >
        <font-awesome-icon :icon="faXmark" :size="'2x'" />
      </button>

      <div class="py-5 px-5">
        <!-- Body Section -->
        <div class="px-3 pt-2 pb-0">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <flow :gap="3" v-html="text" />
        </div>

        <!-- Call To Action (CTA) Button -->
        <div class="px-3 py-3 mt-5 flex justify-center">
          <standard-button
            v-if="buttonUrl"
            class="flex-[0_0_35%]"
            :color="buttonColor"
            :label="buttonText"
            :text="buttonText"
            @click="handleBtnClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, computed, PropType} from 'vue';
import Flow from '../../core/compositions/Flow.vue';
import {useClickOutside} from '../../vue-composition/click/click-outside';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faXmark} from '@fortawesome/pro-regular-svg-icons/faXmark';
import StandardButton from '../../core/button/StandardButton.vue';
import {ImageCollection} from '../../../backend/image-collection/image-collection-types';
import {ButtonColor} from '../../core/button/implementation/utils';

const props = defineProps({
  variation: {type: String, default: 'primary'},
  headline: {type: String, default: ''},
  text: {type: String, default: ''},
  images: {type: Object as PropType<ImageCollection>, default: null},
  showMessage: {type: Boolean, default: true},
  position: {type: String, default: '-top-2 -right-2'},
  topMargin: {type: String, default: 'mt-fl-md-2xl'},
  isCenterPopup: {type: Boolean, default: false},
  buttonUrl: {type: String, default: null},
  buttonText: {type: String, default: null},
  buttonNewTab: {type: Boolean, default: false}
});

const emit = defineEmits(['close']);

// Currently we will only use the first image in the collection
const imageUrl = computed(() => {
  const image = props.images?.[0]?.imageFilename;
  return image
    ? `https://scottsbasslessons.imgix.net${image}?auto=format,compress&q=80&w=800`
    : false;
});

const messageBox = ref<HTMLElement | null>(null);
useClickOutside(messageBox, () => {
  if (props.isCenterPopup) {
    closeMsgBox();
  }
});

const msgClasses = computed(() => {
  const baseClasses = `${props.topMargin} text-xs rounded-lg`;
  const variationClasses: Record<string, string> = {
    light: 'bg-gradient-to-br from-lightest to-light text-black border border-mid',
    primary: 'bg-gradient-to-br from-primary to-pp5 text-black',
    dark: 'bg-darkest text-white border border-dark'
  };
  return `${baseClasses} ${variationClasses[props.variation] || variationClasses.light}`;
});

const buttonColor = computed<ButtonColor>(() => {
  const colorMap: Record<string, ButtonColor> = {
    light: 'invert-primary',
    primary: 'invert',
    dark: 'primary'
  };
  return colorMap[props.variation] || 'light';
});

const showMessage = ref(props.showMessage);
const closeMsgBox = () => {
  showMessage.value = false;
  emit('close'); // Emit close event and let parent handle it
};

const handleBtnClick = () => {
  if (props.buttonUrl && props.buttonNewTab) {
    window.open(props.buttonUrl, '_blank'); // New tab
  } else if (props.buttonUrl) {
    window.open(props.buttonUrl, '_self'); // Current tab
  }
};
</script>
